import Vue from 'vue'
import Router from 'vue-router'
import Gridview from '@/components/grid_cards.vue'

Vue.use(Router)
const router = new Router({

					
  mode: 'history',
  routes: []
})



export default router
