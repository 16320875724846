<template>
    <section id="one" class="main">
            <header class="accent1">
               <h2>{{title}}</h2>
            </header>
            <ul class="tabs">
               <li v-for="item in cardsComputed">
                  <h3>{{item.name}}</h3>
                  <div class="spotlight">
                    <GridTabs redirect="#" :catalougeid="item.id"/>
                  </div>
               </li>
            </ul>
         </section>
</template>
<style scoped>

.main>.inner.alt {
    padding: 3rem 0 !important;
}

.main>.tabs {
    width: 68rem;
    margin: -1.95rem auto 0 auto;
    max-width: calc(100% - 6rem);
}

.main>.tabs>.titles {
    display: -moz-flex;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    list-style: none;
    padding: 0;
    margin: 0;
}

.main>.tabs>.titles:before {
    content: '';
    display: block;
    left: 0;
    width: 100%;
    height: 5.5rem;
    position: absolute;
    z-index: 0;
}

.main>.tabs>.titles>.title {
    -moz-transition-property: color, background-color, box-shadow;
    -webkit-transition-property: color, background-color, box-shadow;
    -ms-transition-property: color, background-color, box-shadow;
    transition-property: color, background-color, box-shadow;
    -moz-transition-duration: 0.2s;
    -webkit-transition-duration: 0.2s;
    -ms-transition-duration: 0.2s;
    transition-duration: 0.2s;
    -moz-flex-grow: 1;
    -webkit-flex-grow: 1;
    -ms-flex-grow: 1;
    flex-grow: 1;
    -moz-flex-shrink: 1;
    -webkit-flex-shrink: 1;
    -ms-flex-shrink: 1;
    flex-shrink: 1;
    padding: 0;
    width: 100%;
    text-align: center;
    height: 5.5rem;
    padding-top: 1.3rem;
    font-weight: 400;
    font-size: 1.25rem;
    position: relative;
    z-index: 1;
}

.main>.tabs>.titles>.title.active {
    box-shadow: 0 3px 0 0 #23b1ec;
    -moz-animation: tab-title 0.5s ease forwards;
    -webkit-animation: tab-title 0.5s ease forwards;
    -ms-animation: tab-title 0.5s ease forwards;
    animation: tab-title 0.5s ease forwards;
}

.main>.tabs>.panels>.panel {
    padding: 6rem 0 4rem 0;
    -moz-transition: opacity 0.2s ease-in-out;
    -webkit-transition: opacity 0.2s ease-in-out;
    -ms-transition: opacity 0.2s ease-in-out;
    transition: opacity 0.2s ease-in-out;
    opacity: 0;
}

.main>.tabs>.panels>.panel.active {
    opacity: 1;
}

.main.accent1 {
    background-color: #23b1ec;
    color: rgba(255, 255, 255, 0.75);
}

.main.accent1 input, .main.accent1 select, .main.accent1 textarea {
    color: #ffffff;
}

.main.accent1 a {
    color: #23b1ec;
}

.main.accent1 strong, .main.accent1 b {
    color: #ffffff;
}

.main.accent1 h1, .main.accent1 h2, .main.accent1 h3, .main.accent1 h4, .main.accent1 h5, .main.accent1 h6 {
    color: #ffffff;
}

.main.accent1 blockquote {
    border-left-color: rgba(255, 255, 255, 0.25);
}

.main.accent1 code {
    background: rgba(255, 255, 255, 0.075);
    border-color: rgba(255, 255, 255, 0.25);
}

.main.accent1 hr {
    border-bottom-color: rgba(255, 255, 255, 0.25);
}

.main.accent1 .box {
    border-color: rgba(255, 255, 255, 0.25);
}
</style>

<script>
import GridTabs from '@/components/GridTabs.vue'
export default {
  name: 'TabCards',
  components:{
    GridTabs
  },
  props: {
    redetail: {
      type: Object,
      default: () => ({})
    }
  },
  created () {
    var self = this
    console.log(this.$root.redetail);
    var Data = {}
    Data.customerId =  this.$root.customerid;
    Data.authToken =  this.$root.purchasekey;
    Data.catalougeId =  this.$root.catalougeid;
    this.$store.dispatch('getCatalogeById',Data).then((res)=>{
        self.cards = res._embedded.items;
        self.title = res._embedded.name;
    }).catch(()=>{
        this.channelNa = true;
    });  
    
  },
  data: () => ({
   channelNa : false, 
   user : {},  
   cards: [],
   title:"",
   languages: [
    {
        "value": "arabic",
        "label": "Arabic"
    },
    {
        "value": "bengali",
        "label": "Bengali"
    },
    {
        "value": "burmese",
        "label": "Burmese"
    },
    {
        "value": "english",
        "label": "English"
    },
    {
        "value": "french",
        "label": "French"
    },
    {
        "value": "german",
        "label": "German"
    },
    {
        "value": "gujarati",
        "label": "Gujarati"
    },
    {
        "value": "hindi",
        "label": "Hindi"
    },
    {
        "value": "italian",
        "label": "Italian"
    },
    {
        "value": "japanese",
        "label": "Japanese"
    },
    {
        "value": "javanese",
        "label": "Javanese"
    },
    {
        "value": "kannada",
        "label": "Kannada"
    },
    {
        "value": "korean",
        "label": "Korean"
    },
    {
        "value": "malayalam",
        "label": "Malayalam"
    },
    {
        "value": "mandarin",
        "label": "Mandarin Chinese"
    },
    {
        "value": "marathi",
        "label": "Marathi"
    },
    {
        "value": "oriya",
        "label": "Oriya"
    },
    {
        "value": "panjabi",
        "label": "Punjbai"
    },
    {
        "value": "persian",
        "label": "Persian"
    },
    {
        "value": "polish",
        "label": "Polish"
    },
    {
        "value": "portuguese",
        "label": "Portuguese"
    },
    {
        "value": "russian",
        "label": "Russian"
    },
    {
        "value": "spanish",
        "label": "Spanish"
    },
    {
        "value": "tamil",
        "label": "Tamil"
    },
    {
        "value": "telugu",
        "label": "Telugu"
    },
    {
        "value": "thai",
        "label": "Thai"
    },
    {
        "value": "turkish",
        "label": "Turkish"
    },
    {
        "value": "ukrainian",
        "label": "Ukrainian"
    },
    {
        "value": "urudu",
        "label": "Urudu"
    },
    {
        "value": "vietnamese",
        "label": "Vietnamese"
    }
    ] 
  }),
  computed: {
    cardsComputed : function(){
        return this.cards.filter(i => i.items_count > 0)
    }
  },
  methods: {
    gethref (id) {
        return this.$root.redetail+id
    },
    widgethref(data){
        return 'https://www.video-widget.com/'+data
    },
    getLanglabel(val){
        var found = this.languages.find(function(element) {
                return element.value == val;
        });        


        return found.label;
    },
    getlangauge(card){



        var str = "Available in <br>"
        if(card.languages){
            for(var i=0;i<card.languages.length;i++){
               str +=  changeCaseFirstLetter(this.getLanglabel(card.languages[i].language)) + " "
            }     
        }
        str += "<br>Language"
        return  str
        function changeCaseFirstLetter(params) {
        if(typeof params === 'string') {
                return params.charAt(0).toUpperCase() + params.slice(1);
        }
        return null;
        }
    }
  }
}
</script>
