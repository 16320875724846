<template>
<div class="container">
      <div class="row">
        <div class="col-md-12 col-sm-12 col-lg-12" v-if="channelNa">
            <h1 class="channel-na">There is no channel available for your region</h1>
        </div>    

        <li class="col-md-4 col-sm-4 col-lg-3 video-widget-grid-item format-standard video-widget-isotope-item" style="" v-for="card in cards" v-if="(cards.length > 0)">
          <a class="video-widget-media-box video-widget-grid-featured-img" disabled="disabled">
            <img :src="card.thumbnail.small" alt="">
              <span class="video-widget-zoom">
                <span class="video-widget-icon" v-html="getlangauge(card)">
                                
                </span>
              </span>
            </a>
            <div class="widget-grid-item-content">

                <div href="#">{{card.name}}</div>
            
<!--               <div class="widget-grid-item-excerpt">
                <p>{{card.description}}</p>
              </div>
              <a :href="gethref(card.id)" class="widget-btn-primary">Read more</a> -->
            </div>
            <!-- <div class="video-widget-grid-item-content">
              <h3>
                <a href="exhibition-single.html">{{card.title}}</a>
              </h3>
              <div class="video-widget-grid-item-excerpt">
                <p>{{card.subtitle}}</p>
              </div>
              <a :href="gethref(card.id)" class="video-widget-btn-primary">Read more</a>
            </div> -->
          </li>       
      </div>
</div>
</template>
<style scoped>

*{
    box-sizing: border-box;
}
:after, :before {
  box-sizing: border-box;
}
.font-white{
    color:#fff!important;
}


.container-video-widget{
    display: table;
    content: " ";
}
.container-video-widget{
  background: #fff;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

}
.clearfix:after,.container-video-widget:after,.row-video-widget:after {
  clear: both;
}
.video-widget-container,.clearfix:before,.video-widget-container:before,.video-widget-row:before {
    display: table;
    content: " ";
}
.video-widget-ul {
  overflow: hidden;
  list-style: none;
}
.video-widget-row{
    padding-top: 50px;
    padding-bottom: 50px;
    margin-right: -15px;
    margin-left: -15px;
}
.video-widget-isotope-grid {
    margin: 0;
    list-style-type: none;
    margin: 0;
    list-style-type: none; 
} 
.video-widget-content li{
  position: absolute;
    left: 0px;
    top: 0px;
    transform: translate(0px, 0px);
}

.channel-na{
    text-align: center;
    padding-top: 50px
}
.video-widget-isotope-grid .video-widget-grid-item {
    margin-bottom: 30px;
    list-style: none;

}
.video-widget-grid-item {
    margin-top:20px;
    margin-bottom: 20px;
    list-style: none;
    cursor: pointer;
    overflow: hidden;

}
a{
    text-decoration: none;
}

.video-widget-isotope .video-widget-isotope-item {
    -webkit-transition-property: -webkit-transform, opacity;
    -moz-transition-property: -moz-transform, opacity;
    -ms-transition-property: -ms-transform, opacity;
    -o-transition-property: -o-transform, opacity;
    transition-property: transform, opacity;
}


.video-widget-media-box {
    position: relative;
    z-index: 1;
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
}

.video-widget-media-box  img{
  max-width: 100%;
}

.video-widget-content a {
    color: #dabc74;
}

.video-widget-content a:hover, .video-widget-content a:active, .video-widget-content a:focus {
    text-decoration: none;
    outline: 0;
    color: #5e5e5e;
}

.video-widget-content a > img {
    vertical-align: middle;
    border: none;
    height: auto;
    max-width: 100%;
}


.video-widget-media-box .video-widget-zoom, .video-widget-media-box .video-widget-expand {
    position: absolute;
    width: 100%;
    height: 100%;
    text-align: center;
    font-size: 1.5em;
    z-index: 99;
    left: 0;
    top: 0;
    margin: 0;
    cursor: pointer;
    background-color: rgba(0,0,0,.66);
    opacity: 1;
    text-align: center !important;
    -webkit-transition: all 0.3s ease 0.2s;
    -moz-transition: all 0.3s ease 0.2s;
    -ms-transition: all 0.3s ease 0.2s;
    -o-transition: all 0.3s ease 0.2s;
    transition: all 0.3s ease 0.1s;
    padding-top: 14px;
    transform: translateY(101%);
}
.video-widget-zoom .video-widget-un{
    text-align: center;
    min-width: 90%;
    padding: 0;
    margin: 14px 0 17px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    
}
.video-widget-zoom .video-widget-like-comment{
    text-align: center;
    min-width: 80%;
    margin: 0 auto;
    padding-top: 15px;
}
/*.video-widget-media-box .video-widget-zoom .video-widget-icon{
  background-color: #dabc74;
}*/
.video-widget-media-box:hover .video-widget-zoom, .video-widget-media-box:hover .expand {
    opacity: 1;
    -webkit-transition: opacity .3s .2s;
    -moz-transition: opacity .3s .2s;
    -ms-transition: opacity .3s .2s;
    -o-transition: opacity .3s .2s;
    color: #FFF !important;
    padding: 10px;
    transform: translateY(0);
    transition: transform 1s;
}
.video-widget-grid-item-content, .video-widget-grid-item-header {
    padding: 20px;
    border: 1px solid #eee;
    border-radius: 2px;
    position: relative;
}
.video-widget-grid-item-content:before, .video-widget-grid-item-content:after {
    content: " ";
    display: table;
}


.video-widget-grid-item h3{
    margin: 0 0 20px 0;
    color: #333;
    font-weight: 400;
    line-height: 1.5em;
    letter-spacing: .5px;
}
.video-widget-grid-item h1 a,.video-widget-grid-item h2 a,.video-widget-grid-item h3 a,.video-widget-grid-item h4 a,.video-widget-grid-item h5 a,.video-widget-grid-item h6 a {
    color: #333;
    text-decoration: none;
}
.video-widget-grid-item h3 a:hover{
  color: #dabc74;
  opacity: 0.9;
}
.video-widget-grid-item-excerpt{
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    font-size: 100%;
}
.video-widget-grid-item p {
    margin: 0 0 20px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}
.video-widget-btn-primary{
    background-color: #dabc74;
    padding-right: 20px;
    -webkit-transition: all 0.3s ease 0.2s;
    -webkit-transition: all 0.3s ease 0.1s;
    transition: all 0.3s ease 0.1s;
    text-shadow: none;
    border-width: 0;
    text-decoration: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    text-transform: uppercase;
    letter-spacing: .07em;
    color: #fff;
    position: relative;
    font-family: 'Roboto Condensed', sans-serif;
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
}
.video-widget-btn-primary:hover{
      background-color: #eac366;
      opacity: 0.9;
} 

.video-widget-grid-item-content:after {
    clear: both;
}
.video-widget-header{
    padding:10px;
}
.video-widget-profile-image{
    border-radius: 50%;
    height: 100px;
    width: 100px;
    background-size: 100% 100%;
    width: 100px;
    float: left;
    margin-left: 18px;
}
@media screen and (max-width: 600px) {
  .video-widget-profile-image{ 
    margin: 0 auto;
  }
  .profile-information{
    font-size: 20px;
    text-align: center;
  }
}
.profile-information{
    height: 100px;
    margin-left: 34px;
}
.profile-information .username{
    font-size: 37px;
    font-weight: 600;
    color: #000000;
    margin-left: 10px;
}
.profile-information .followers{
    font-size: 20px;
    color: #000000;
}
.profile-href{
     cursor: pointer;
    text-decoration: none;
    color: #000000;
}
</style>

<script>
export default {
  name: 'GridTabs',
  props: {
    redetail: {
      type: Object,
      default: () => ({})
    },
    catalougeid:{
        type:Object,
        default:()=>({})
    }

  },
  created () {
    var self = this
    console.log(this.$root.redetail);
    var Data = {}
    Data.customerId =  this.$root.customerid;
    Data.authToken =  this.$root.purchasekey;
    Data.catalougeId =  this.catalougeid;
    this.$store.dispatch('getCatalogeById',Data).then((res)=>{
        self.cards = res._embedded.items;
    }).catch(()=>{
        this.channelNa = true;
    });  
    
  },
  data: () => ({
   channelNa : false, 
   user : {},  
   cards: [],
   languages: [
    {
        "value": "arabic",
        "label": "Arabic"
    },
    {
        "value": "bengali",
        "label": "Bengali"
    },
    {
        "value": "burmese",
        "label": "Burmese"
    },
    {
        "value": "english",
        "label": "English"
    },
    {
        "value": "french",
        "label": "French"
    },
    {
        "value": "german",
        "label": "German"
    },
    {
        "value": "gujarati",
        "label": "Gujarati"
    },
    {
        "value": "hindi",
        "label": "Hindi"
    },
    {
        "value": "italian",
        "label": "Italian"
    },
    {
        "value": "japanese",
        "label": "Japanese"
    },
    {
        "value": "javanese",
        "label": "Javanese"
    },
    {
        "value": "kannada",
        "label": "Kannada"
    },
    {
        "value": "korean",
        "label": "Korean"
    },
    {
        "value": "malayalam",
        "label": "Malayalam"
    },
    {
        "value": "mandarin",
        "label": "Mandarin Chinese"
    },
    {
        "value": "marathi",
        "label": "Marathi"
    },
    {
        "value": "oriya",
        "label": "Oriya"
    },
    {
        "value": "panjabi",
        "label": "Punjbai"
    },
    {
        "value": "persian",
        "label": "Persian"
    },
    {
        "value": "polish",
        "label": "Polish"
    },
    {
        "value": "portuguese",
        "label": "Portuguese"
    },
    {
        "value": "russian",
        "label": "Russian"
    },
    {
        "value": "spanish",
        "label": "Spanish"
    },
    {
        "value": "tamil",
        "label": "Tamil"
    },
    {
        "value": "telugu",
        "label": "Telugu"
    },
    {
        "value": "thai",
        "label": "Thai"
    },
    {
        "value": "turkish",
        "label": "Turkish"
    },
    {
        "value": "ukrainian",
        "label": "Ukrainian"
    },
    {
        "value": "urudu",
        "label": "Urudu"
    },
    {
        "value": "vietnamese",
        "label": "Vietnamese"
    }
    ] 
  }),
  computed: {
  },
  methods: {
    gethref (id) {
        return this.$root.redetail+id
    },
    widgethref(data){
        return 'https://www.video-widget.com/'+data
    },
    getLanglabel(val){
        var found = this.languages.find(function(element) {
                return element.value == val;
        });        


        return found.label;
    },
    getlangauge(card){



        var str = "Available in <br>"
        if(card.languages){
            for(var i=0;i<card.languages.length;i++){
               str +=  changeCaseFirstLetter(this.getLanglabel(card.languages[i].language)) + " "
            }     
        }
        str += "<br>Language"
        return  str
        function changeCaseFirstLetter(params) {
        if(typeof params === 'string') {
                return params.charAt(0).toUpperCase() + params.slice(1);
        }
        return null;
        }
    }
  }
}
</script>
