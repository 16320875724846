<template>
  <div id="app2">
        <link rel="stylesheet" 
        href="https://use.fontawesome.com/releases/v5.2.0/css/all.css" 
        integrity="sha384-hWVjflwFxL6sNzntih27bfxkr27PmbbK/iSvJ+a4+0owXq79v+lsFkW54bOGbiDQ" 
        crossorigin="anonymous">
<!--     <h3 style="text-align: center"> props key  {{$root.purchasekey}}</h3>
    <h3 style="text-align: center"> props customerid  {{$root.customerid}}</h3> -->
    <GridView :redirect="$root.redetail" v-if="$root.viewtype == 'grid'"/>
    <CarouselView :redirect="$root.redetail" v-if="$root.viewtype == 'carousel'"/>
    <TabView :redirect="$root.redetail" v-if="$root.viewtype == 'tab'"/>
  </div>
</template>

<script>
import GridView from '@/components/grid_cards.vue'
import CarouselView from '@/components/carousel_cards.vue'
import TabView from '@/components/tab_view.vue'
export default {
  props: {
   purchasekey: {
      type: String,
      default: () => ({})
   },
   customerid: {
      type: String,
      default: () => ({})
   },
   redetail: {
      type: String,
      default: () => ({})
   },   
   catalougeid: {
      type: String,
      default: () => ({})
   },
   viewtype: {
      type: String,
      default: () => ({})
   }
  },
  components: {
    GridView,
    CarouselView,
    TabView
  },
  data () {
    return {
    }
  },
  created () {
    console.log(this.$root.purchasekey)
      
  }
}
</script>
