import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
import mutations from './mutations'
import axios from 'axios'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  	userData: {},
  	accessToken : '',

  },
  getters,
  mutations,
  actions: {
  	getCatalogeById(state,payload){
      var dyHeader = {};
      if(true){
        dyHeader.customerId = payload.customerId
      }else{
        dyHeader.customerId = payload.customerId
      }
  		var self = this;
  		return new Promise((resolve, reject) => {
	         axios.get('https://api.appmastery.co/api/v1/apps/catalogues?id='+`${payload.catalougeId}`,
	           {
        	     headers: dyHeader 
        	}).then((response) => {
	          resolve(response.data);
	        }).catch(() => {
	          reject();
	        });
      	});
  	}
  }
})
