<template>
<div class="container cont-carousel">  
        <h3 class="title-carousel">{{title}}</h3>
        <ul class="ul-carousel" :style="{'transform': `translateX(${this.transComp})`}">
        <li class="widget-grid-item-carousel format-standard widget-isotope-item" style="" v-for="card in cards">
          <a href="#" class="widget-media-box widget-grid-featured-img">
            <img :src="card.thumbnail.small" alt="">
              <span class="widget-zoom">
                <span class="widget-icon">
                </span>
              </span>
            </a>
            <div class="widget-grid-item-content">

                <a href="#">{{card.name}}</a>
            
<!--               <div class="widget-grid-item-excerpt">
                <p>{{card.description}}</p>
              </div>
              <a :href="gethref(card.id)" class="widget-btn-primary">Read more</a> -->
            </div>
          </li>

        </ul>   
        <a class="next-btn" @click="clickarrow('prev')" v-if="showprev"></a>      
        <a class="prev-btn" @click="clickarrow('next')" v-if="shownext"></a>      
</div>
</template>
<style scoped>

*{
    box-sizing: border-box;
}
:after, :before {
  box-sizing: border-box;
}
 

.container-widget{
    display: table;
    content: " ";
}
.container-widget{
  background: #fff;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

}
.cont-carousel{
  overflow: hidden;
  position: relative
}
.cont-carousel:hover .next { 
 }
.ul-carousel{
    margin: 0;
    padding: 0;
    padding-top: 20px;
    padding-bottom: 20px;
    list-style: none;
    white-space: nowrap;
    padding-bottom:20px;
    transition: transform 1s;

}
.clearfix:after,.container-widget:after,.row-widget:after {
  clear: both;
}
.widget-container,.clearfix:before,.widget-container:before,.widget-row:before {
    display: table;
    content: " ";
}
.widget-ul {
  overflow: hidden;
  list-style: none;
}
.widget-row{
    padding-top: 50px;
    padding-bottom: 50px;
    margin-right: -15px;
    margin-left: -15px;
}
.widget-isotope-grid {
    margin: 0;
    list-style-type: none;
    margin: 0;
    list-style-type: none; 
} 
.widget-content li{
  position: absolute;
    left: 0px;
    top: 0px;
    transform: translate(0px, 0px);
}




.widget-isotope-grid .widget-grid-item-carousel {
    margin-bottom: 30px;
    list-style: none;

}
.widget-grid-item-carousel {
    background: #fff;
    margin-top: 10;
    margin-bottom: 10px;
    list-style: none;
    max-width :25%;
    position: relative;
    display: inline-block;

}


.widget-isotope .widget-isotope-item {
    -webkit-transition-property: -webkit-transform, opacity;
    -moz-transition-property: -moz-transform, opacity;
    -ms-transition-property: -ms-transform, opacity;
    -o-transition-property: -o-transform, opacity;
    transition-property: transform, opacity;
}


.widget-media-box {
    position: relative;
    z-index: 1;
    display: inline-block;
    max-width: 100%;
}

.widget-media-box  img{
  max-width: 100%;
}

.widget-content a {
    color: #dabc74;
}

.widget-content a:hover, .widget-content a:active, .widget-content a:focus {
    text-decoration: none;
    outline: 0;
    color: #5e5e5e;
}

.widget-content a > img {
    vertical-align: middle;
    border: none;
    height: auto;
    max-width: 100%;
}


.widget-media-box .widget-zoom, .widget-media-box .widget-expand {
    position: absolute;
    width: 100%;
    height: 100%;
    text-align: center;
    font-size: 1.5em;
    z-index: 99;
    left: 0;
    top: 0;
    margin: 0;
    background-color: rgba(0,0,0,.4);
    opacity: 0;
    -webkit-transition: all 0.3s ease 0.2s;
    -moz-transition: all 0.3s ease 0.2s;
    -ms-transition: all 0.3s ease 0.2s;
    -o-transition: all 0.3s ease 0.2s;
    transition: all 0.3s ease 0.1s;
}
.widget-media-box .widget-zoom .widget-icon{
  background-color: #dabc74;
}
.widget-media-box:hover .widget-zoom, .widget-media-box:hover .expand {
    opacity: 1;
    -webkit-transition: opacity .3s .2s;
    -moz-transition: opacity .3s .2s;
    -ms-transition: opacity .3s .2s;
    -o-transition: opacity .3s .2s;
    transition: opacity .3s .2s;
}
.widget-grid-item-content, .widget-grid-item-header {
    padding: 5px;
    border-radius: 2px;
    position: relative;
    white-space: initial;
}
.widget-grid-item-content:before, .widget-grid-item-content:after {
    content: " ";
    display: table;
}


.widget-grid-item-carousel a{
    margin: 0 0 20px 0;
    color: #333;
    font-weight: 400;
    line-height: 1.5em;
    letter-spacing: .5px;
}
.widget-grid-item-carousel a,.widget-grid-item-carousel h2 a,.widget-grid-item-carousel h3 a,.widget-grid-item-carousel h4 a,.widget-grid-item-carousel h5 a,.widget-grid-item-carousel h6 a {
    color: #333;
    text-decoration: none;
}
.widget-grid-item-carousel:hover a{
  color: #dabc74;
  opacity: 0.9;
}
.widget-grid-item-excerpt{
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    font-size: 100%;
}
.widget-grid-item-excerpt p {
    margin: 0 0 20px;
    min-height: 72px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}
.widget-btn-primary{
    background-color: #dabc74;
    padding-right: 20px;
    -webkit-transition: all 0.3s ease 0.2s;
    -webkit-transition: all 0.3s ease 0.1s;
    transition: all 0.3s ease 0.1s;
    text-shadow: none;
    border-width: 0;
    text-decoration: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    text-transform: uppercase;
    letter-spacing: .07em;
    color: #fff;
    position: relative;
    font-family: 'Roboto Condensed', sans-serif;
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
}
.widget-btn-primary:hover{
      background-color: #eac366;
      opacity: 0.9;
} 

.widget-grid-item-content:after {
    clear: both;
}
.next-btn {    
    position: absolute;
    top: 0%;
    height: 100%;
}
.prev-btn{
    position: absolute;
    top: 0%;
    height: 100%;
    right: 0px;

}



.next-btn , .prev-btn{
    width: 40px;
    color: inherit;
    text-decoration: none;
    background-color: #f7f4ee03;
    padding-right: 20px;
    -webkit-transition: all 0.3s ease 0.2s;
    -webkit-transition: all 0.3s ease 0.1s;
    transition: all 0.3s ease 0.1s;
    text-shadow: none;
    border-width: 0;
    text-decoration: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    text-transform: uppercase;
    letter-spacing: .07em;
    color: #fff;
    font-family: 'Roboto Condensed', sans-serif;
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
}

.next-btn::after {
  content: " "
}  
 .prev-btn::after{
  content: " "
}  
.next-btn::after{
  transition:2s;
  -webkit-box-sizing: border-box;
    -webkit-transform: rotate(-45deg);
    border-left: 6px solid #dabc74;
    border-radius: 3px;
    border-top: 6px solid #dabc74;
    box-sizing: border-box;
    content: "";
    height: 40px;
    left: 50%;
    margin-left: -15px;
    margin-top: -10px;
    position: absolute;
    top: 50%;
    transform: rotate(-45deg);
    width: 40px;
}
.prev-btn::after{
    transition:2s;
    -webkit-box-sizing: border-box;
    -webkit-transform: rotate(-45deg);
    border-bottom: 6px solid #dabc74;
    border-radius: 3px;
    border-right: 6px solid #dabc74;
    box-sizing: border-box;
    content: "";
    height: 40px;
    margin-right: -5px;
    margin-top: -15px;
    position: absolute;
    right: 50%;
    top: 50%;
    transform: rotate(-45deg);
    width: 40px;
  
}
.title-carousel{
  padding-top: 10px;
}
@media (min-width: 300px) {
  .widget-grid-item-carousel {
    max-width: 100%;
  }
}

@media (min-width: 576px) {
  .widget-grid-item-carousel {
    max-width: 100%;
  }
}
@media (min-width: 768px){
  .widget-grid-item-carousel {
    max-width: 50%;
  }
}
@media (min-width: 992px){
  .widget-grid-item-carousel {
    max-width: 25%;
  }
}

</style>

<script>
export default {
  name: 'CarouselCards',
  props: {
    redetail: {
      type: Object,
      default: () => ({})
    }
  },
  beforeCreate () {


  },
  created () {
    var self = this
    console.log(this.$root.redetail);
    var Data = {}
    Data.customerId =  this.$root.customerid;
    Data.authToken =  this.$root.purchasekey;
    Data.catalougeId =  this.$root.catalougeid;
    this.$store.dispatch('getCatalogeById',Data).then((res)=>{
        self.cards = res._embedded.items;
        self.title = res._embedded.title;

    });

  },
  mounted(){
    // console.log(this.$root.redetail);
    // var carousels = document.getElementsByClassName('widget-grid-item-carousel');
    // this.widthLi = carousels[0].offsetWidth;
    // this.countitems = carousels.length;
    // if(this.countitems > 3){
    //   this.shownext = true
    // }else{
    //   this.shownext = false;
    //   this.showprev = false;
    // }
  },
  data: () => ({
    cards: [],
    title: '',
    trans : 0,
    widthLi : 0,
    countitems : 0,
    currentcarouselItem : 0,
    shownext:false,
    showprev:false,
  }),
  watch: {
    cards: function (val) {
      self = this
          self.widthLi = 280;
          self.countitems = self.cards.length;
          if(self.countitems > 3){
            self.shownext = true
          }else{
            self.shownext = false;
            self.showprev = false;
          }

    }
  },
  computed: {
    transComp (){
      return this.trans+'px'
    },

  },
  methods: {
    clickarrow (type){
      if(type == 'next'){
        if(this.currentcarouselItem  <= this.countitems -5){
          this.trans -= this.widthLi;
          this.currentcarouselItem +=1;
          if(this.currentcarouselItem  == this.countitems -4){
            this.shownext = false;
          }
          if(this.currentcarouselItem >= 0){
            this.showprev = true;
          }
        }else{
          return false;
        }
        
      }else if ('prev') {
        if(this.currentcarouselItem > 0){
          this.trans += this.widthLi;
          this.currentcarouselItem -= 1;
          if(this.currentcarouselItem  == 0){
            this.showprev = false;
          }
          if(this.currentcarouselItem  <= this.countitems -5){
            this.shownext = true;
          }
        }else{
          return false;
        }
      }
    },
    gethref (id) {
        return this.$root.redetail+id
    },
  }
}
</script>
